<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addOffer'})">العروض</dashboard-page-title>
    <main-table :fields="fields" :list_url="'merchant/flash-sales'" paginationName="عروض"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      fields: [
        // '#',
        { label: 'الاسم', key: 'name', class: 'text-right' },
        { label: 'النوع', key: 'type', class: 'text-right' },
        { label: 'تاريخ البدء', key: 'start_date', class: 'text-right' },
        { label: 'تاريخ الانتهاء', key: 'end_date', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'تاريخ الانشاء', key: 'created_at', class: 'text-right' }
      ]
    }
  },
  methods: {
    addMerchant () {
    }
  }
}
</script>
